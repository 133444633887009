<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.contentType"
              :options="codes.contentType"
              class="invoice-filter-select el-def"
              placeholder="컨텐츠구분"
              :reduce="(option) => option.code"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
            <v-select
              v-model="query.isCommentSurvey"
              :options="codes.isCommentSurvey"
              class="invoice-filter-select el-def"
              placeholder="투표형구분"
              :reduce="(option) => option.code"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="등록일(From)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="등록일(To)"
            />
            <b-button
              variant="primary"
              class="btn-search mr-50"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              댓글 리스트 [{{ totalRecords }}] 건
            </h5>
            <b-form-checkbox
              v-model="showCommentSurvey"
              checked="true"
              class="custom-control-primary ml-2"
              name="check-button"
              switch
            >
              투표형 보기
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDown()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        v-if="!showCommentSurvey"
        :no-wrap="true"
        :items="commentTable"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        selectable
        sticky-header
        select-mode="single"
        class="position-relative"
        style="max-height: 65vh"
      >
        <!-- Column: No -->
        <template #cell(commentId)="data">
          {{ data.value }}
        </template>

        <template #cell(title)="data">
          {{ data.value.length > 20 ? data.value.substring(0, 20) + "..." : data.value }}
        </template>

        <template #cell(createDate)="data">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <span class="text-nowrap">
              {{ data.value | $dateFormatter('YYYY-MM-DD') }}
            </span>
            <span class="text-nowrap">
              {{ data.value | $dateFormatter('HH:mm') }}
            </span>
          </div>
        </template>

        <template #cell(deleteDate)="data">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <span
              v-if="data.item.isDelete === true"
              class="text-nowrap"
            >
              {{ data.value | $dateFormatter('YYYY-MM-DD') }}
            </span>
            <span
              v-if="data.item.isDelete === true"
              class="text-nowrap"
            >
              {{ data.value | $dateFormatter('HH:mm') }}
            </span>
            <b-button
              v-else
              variant="outline-danger"
              size="sm"
              class="mr-1"
              @click.prevent="showDeleteModal(data.item.commentId)"
            >
              <span>삭제</span>
            </b-button>
          </div>
        </template>

        <template #cell(isUserDelete)="data">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <span
              v-if="data.item.isUserDelete === true"
              class="text-nowrap"
            >
              O
            </span>
          </div>
        </template>
      </b-table>

      <b-table
        v-else-if="showCommentSurvey"
        :no-wrap="true"
        :items="commentTable"
        :fields="commentSurveyTableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        selectable
        sticky-header
        select-mode="single"
        class="position-relative"
        style="max-height: 65vh"
      >
        <!-- Column: No -->
        <template #cell(commentId)="data">
          {{ data.value }}
        </template>

        <template #cell(title)="data">
          {{ data.value.length > 20 ? data.value.substring(0, 20) + "..." : data.value }}
        </template>

        <template #cell(createDate)="data">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <span class="text-nowrap">
              {{ data.value | $dateFormatter('YYYY-MM-DD') }}
            </span>
            <span class="text-nowrap">
              {{ data.value | $dateFormatter('HH:mm') }}
            </span>
          </div>
        </template>

        <template #cell(deleteDate)="data">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <span
              v-if="data.item.isDelete === true"
              class="text-nowrap"
            >
              {{ data.value | $dateFormatter('YYYY-MM-DD') }}
            </span>
            <span
              v-if="data.item.isDelete === true"
              class="text-nowrap"
            >
              {{ data.value | $dateFormatter('HH:mm') }}
            </span>
            <b-button
              v-else
              variant="outline-danger"
              size="sm"
              class="mr-1"
              @click.prevent="showDeleteModal(data.item.commentId)"
            >
              <span>삭제</span>
            </b-button>
          </div>
        </template>

        <template #cell(isUserDelete)="data">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <span
              v-if="data.item.isUserDelete === true"
              class="text-nowrap"
            >
              O
            </span>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="10"
        per-page="10"
        align="center"
      />
    </b-card>

    <div>
      <b-modal
        v-model="modalVisible"
        title="정말 삭제하시겠습니까?"
        no-close-on-esc
        no-close-on-backdrop
        @ok.prevent="deleteComment()"
      >
        <div>
          <v-select
            v-model="query.deleteType"
            :options="codes.deleteType"
            class="invoice-filter-select el-def"
            placeholder="삭제사유를 선택하세요"
            :reduce="(option) => option.code"
          />
        </div>
        <p
          v-if="warningMessage"
          class="text-danger"
        >
          {{ warningMessage }}
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, onMounted, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { saveAs } from "file-saver"
import dayjs from 'dayjs'

export default {
  components: {
    vSelect,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
      fetchComments()
    })
    const toast = useToast()
    const codes = ref({ contentType: [], deleteType: [], isCommentSurvey: [{ code: false, label: "일반형 댓글" }, { code: true, label: "투표형 댓글" }] })
    const modalVisible = ref(false)

    // Query Data
    const query = ref({
      contentType: null,
      deleteType: 1,
      isCommentSurvey: null,
      startDate: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    })

    const commentId = ref(0)
    const sortBy = ref('commentId')
    const isSortDesc = ref(true)
    const commentTable = ref([])
    const warningMessage = ref()
    const showCommentSurvey = ref(false)

    const tableColumns = ref([
      {
        key: 'commentId', label: 'ID', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'contentTypeName', label: '컨텐츠 구분', sortable: true, thStyle: { width: '9%' },
      },
      {
        key: 'title', label: '컨텐츠 제목', sortable: true, thStyle: { width: '15%' },
      },
      {
        key: 'commentText', label: '댓글 내용', sortable: true,
      },
      {
        key: 'userName', label: '작성자', sortable: true, thStyle: { width: '6%' },
      },
      {
        key: 'userId', label: '작성자Id', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'createDate', label: '댓글 등록일시', thStyle: { width: '8%', textAlign: 'center' },
      },
      {
        key: 'deleteDate', label: '삭제/삭제일시', thStyle: { width: '8%', textAlign: 'center' },
      },
      {
        key: 'deleteType', label: '삭제사유', thStyle: { width: '7%', textAlign: 'center' },
      },
      {
        key: 'isUserDelete', label: '사용자삭제', thStyle: { width: '7%', textAlign: 'center' },
      },
    ])

    const commentSurveyTableColumns = ref([
      {
        key: 'commentId', label: 'ID', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'contentTypeName', label: '컨텐츠 구분', sortable: true, thStyle: { width: '9%' },
      },
      {
        key: 'title', label: '컨텐츠 제목', sortable: true, thStyle: { width: '15%' },
      },
      {
        key: 'commentText', label: '댓글 내용', sortable: true,
      },
      {
        key: 'commentSurveyName', label: '투표 제목', sortable: true,
      },
      {
        key: 'firstCommentSurveyAnswerText', label: '보기 옵션', sortable: true,
      },
      {
        key: 'userName', label: '작성자', sortable: true, thStyle: { width: '6%' },
      },
      {
        key: 'userId', label: '작성자Id', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'createDate', label: '댓글 등록일시', thStyle: { width: '8%', textAlign: 'center' },
      },
      {
        key: 'deleteDate', label: '삭제/삭제일시', thStyle: { width: '8%', textAlign: 'center' },
      },
      {
        key: 'deleteType', label: '삭제사유', thStyle: { width: '7%', textAlign: 'center' },
      },
      {
        key: 'isUserDelete', label: '사용자삭제', thStyle: { width: '7%', textAlign: 'center' },
      },
    ])

    const fetchCodes = () => {
      axios
        .get('/fa/comment/codes')
        .then(rs => {
          // eslint-disable-next-line no-shadow
          const { commentContentType, deleteType } = rs.data
          codes.value.contentType = commentContentType
          codes.value.deleteType = deleteType
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '코드 데이터를 불러오는데 실패하였습니다.',
              icon: 'AlterTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const currPage = ref(1)
    const totalRecords = ref(0)
    const fetchComments = () => {
      axios
        .post('/fa/comment/list', {
          contentType: query.value.contentType,
          startDate: query.value.startDate,
          endDate: query.value.endDate,
          isCommentSurvey: query.value.isCommentSurvey,
          sort: {
            predicate: sortBy.value,
            reverse: isSortDesc.value,
          },
          pagination: {
            number: currPage.value,
            count: 10,
          },
        })
        .then(rs => {
          const { items, totalRecord } = rs.data

          commentTable.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const showDeleteModal = id => {
      commentId.value = id
      modalVisible.value = true
    }
    const deleteComment = () => {
      if (!commentId) {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      axios
        .delete(
          `/fa/comment/${commentId.value}?deleteType=${query.value.deleteType}`,
        )
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 삭제 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          modalVisible.value = false
          fetchComments()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '삭제하는 과정에서 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const refetchData = () => {
      fetchComments()
    }

    watch(query.value, () => {
      fetchComments()
    })
    watch([currPage], () => {
      fetchComments()
    })

    const excelDown = () => {
      axios.post('/fa/comment/list-excel', {
        contentType: query.value.contentType,
        startDate: query.value.startDate,
        endDate: query.value.endDate,
        isCommentSurvey: query.value.isCommentSurvey,
        showCommentSurvey: showCommentSurvey.value,
      }, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])
          let fileName = '댓글관리_리스트_일반형'
          if (showCommentSurvey.value) {
            fileName = '댓글관리_리스트_투표형'
          }

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })
    }

    return {
      sortBy,
      currPage,
      totalRecords,
      isSortDesc,
      tableColumns,
      commentSurveyTableColumns,
      fetchCodes,
      toast,
      query,
      codes,
      fetchComments,
      excelDown,
      commentTable,
      watch,
      refetchData,
      deleteComment,
      commentId,
      modalVisible,
      showDeleteModal,
      warningMessage,
      showCommentSurvey,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

table { table-layout: fixed; }
</style>
